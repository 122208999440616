<template>
	<div class="perMap"
	v-loading="loading" 
	element-loading-text="业绩地图加载中..."
	element-loading-spinner="el-icon-loading"
	element-loading-background="rgba(255, 255, 255, .8)"
	>
		<div class="top-tit">和隆优化业绩地图</div>
		<div class="bot-tit flex">
			<div class="bot-tit1" @click="toCeipage">CEI统计</div>
			<div class="bot-tit2" @click="toOtherpage">通讯总览</div>
		</div>
		<div class="box">
					<div class="mapContainer flex">
			<div class="mapleft">
				<div class="mapleft-1">
					<div class="mapleft-1-tit">监控生产线</div>
					<div class="mapleft-1-data flex align-cen">
						<div class="name">在线产线</div>
						<div class="num">963</div>
						<div class="text">台</div>
					</div>
					<div class="mapleft-1-data mapleft-1-data1 flex align-cen">
						<div class="name">离线产线</div>
						<div class="num">276</div>
						<div class="text">台</div>
					</div>
				</div>
				<div class="mapleft-2">
					<div class="mapleft-1-tit">各装置总览</div>
					<div class="mapleft-2-data" v-for="(item, index) in list" :key="index">
						<div class="num">
							{{ item.value }}
						</div>
						<div class="name">
							{{ item.name }}
						</div>
					</div>
					<div class="mapleft-2-data" >
						<div class="num">
							 108 
						</div>
						<div class="name">
							 化工
						</div>
					</div>
				</div>
			</div>
			<div class="mapcen">
				<div class="mapcen-tit">
					<div class="name">实际动态总数</div>
					<div class="mapcen-data flex">
						<div class="num" v-for="(item, index) in numarr" :key="index">
							{{ item }}
						</div>
					</div>
				</div>
				<div class="mapchina" id="mapMain" ref="myEcharts" :option="option"></div>
			</div>
			<div class="mapright ma-auto">
				<div class="mapright-1">
					<div class="flex">
						<div class="mapleft-1-tit">各省市项目情况汇总</div>
						<div class="words" @click="dialogFormVisible = true">更多</div>
						<!-- <div class="words" @click="toSummary">更多</div> -->
					</div>
					<div class="maptab">
						<dv-scroll-board :config="config"
							style="width: 20vw; height: 48vh;overflow: hidden; margin-top: -0vh;" @click="bgqh" />
					</div>
					<div class="mapleft-1-tit" style="margin-top: 2vh">
						排名前五省市项目情况汇总
					</div>
					<div class="mapline" style="margin-top: 2vh">
						<dv-capsule-chart :config="config1" style="width: 19vw; height: 20vh" />
					</div>
				</div>
			</div>
		</div>
		</div>
		<el-dialog title="各省市项目情况汇总" style="" :visible.sync="dialogFormVisible">
			<more @cancel="cancel" ref="childrenDom"></more>
		</el-dialog>
	</div>
</template>
<script>
	import more from "@/views/PerformanceMap/more.vue";
	import {
		getSpread,
	} from "@/api/index/index";
	import {
		loginUser,getlogin,log_add
	} from "@/api/auth/index" //登录
	import china from "./_index/china.json";
	export default {
		name: "mapindex",
		components: {
			more,
		},
		data: () => {
			return {
				dialogFormVisible: false, //判断表单是否显示
				count: 0,
				drop_line: 0,
				online: 0,
				loading: true,
				list: [],
				numarr: [],
				config: {},
				config1: {},
				option: "",
				myChart: "",
				username:'',
				password:'',
				UserInfo:[],
				city: [
					{name: "苏州市", value: 42},
					{name: "运城市", value: 37},
					{name: "徐州市", value: 32},
					{name: "唐山市", value: 26},
					{name: "盐城市", value: 14},
				],
				prodd:[
					["云南省",12,13,5,3,5],
					["天津市",6,3,5,8,5],
					["山东省",16,3,7,6,1],
					["山西省",3,13,5,3,15],
					["广东省",3,35,18,5,6],
					["江苏省",34,18,23,15,6],
					["河北省",31,5,8,7,11],
					["浙江省",13,5,6,3,7],
					["甘肃省",21,5,3,5,6],
					["福建省",2,6,3,3,5],
					["辽宁省",12,33,5,3,5],
					["重庆市",13,5,23,5,6],
					["黑龙江省",26,26,23,5,6]
				]
			};
		},
		mixins: [china],
		created() {
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))	
			if (this.$route.query.type == 1) {
				this.user=this.$route.query.user
				this.login(this.user)
			}
		},
		mounted() {
			// this.chinaEcharts()
			this.getData();
		},
		computed: {},

		watch: {
			option: {
				handler(newVal, oldVal) {
					if (this.myChart) {
						if (newVal) {
							this.loading = false;
							this.myChart.setOption(newVal, true);
						} else {
							this.myChart.setOption(oldVal);
						}
					} else {
						this.drawLine();
					}
				},
			},
		},
		methods: {
			// 登录
			login(user) {
				getlogin({
					account:user
				}).then(res=>{
			        console.log(res,"用户名密码")
					if(res){
						console.log(132)
					this.username=res.a
					this.password=res.p
					loginUser({
						account: this.username,
						password:this.password,
					})
					.then(res => {
						console.log("1111",res)
						if (res.info.code == 500) {
							return this.$message.error(res.info.msg);
						} else {
							localStorage.setItem("UserInfo", JSON.stringify(res.msg))
							localStorage.setItem("authInfo", JSON.stringify(res.auth))
						}
					})}
					else{
						return this.$message.error("登录异常，请联系管理员进行解决");	
					}
				})
		

			},
			cancel() {
				this.dialogFormVisible = false
			},
			domColor(dataIndex, data) {
				// console.log(data)
				// 在此判断设置颜色
				// const newAry = ["#ddb926", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", ];
				let newAry = []
				for (let i in data) {
					data[i].cei_state ? newAry.push("#ddb926") : newAry.push("#ff0f22")
				}
				// console.log(newAry, "颜色")
				return newAry[dataIndex]
			},
			converStr(str) {
				//给数字字符串添加逗号分隔符
				if (/\./.test(str)) {
					return str
						.replace(/\d(?=(\d{1})+\.)/g, "$&,")
						.split("")
						.reverse()
						.join("")
						.replace(/\d(?=(\d{1})+\.)/g, "$&,")
						.split("")
						.reverse()
						.join("");
				} else {
					return str.replace(/\d(?=(\d{1})+$)/g, "$&,");
				}
			},
			getData() {
				getSpread({})
					.then((res) => {
						this.loading = false;
						this.online = res.online;
						this.drop_line = res.drop_line;
						this.list = res.product_type_num;
						var pro = res.province;
						pro.forEach((elem, index) => {
							elem.length = 6;
							// console.log(elem,"这里");
						})
						console.log(JSON.stringify(pro),"这里");
						//  将总数分割为数组
						let strNumber = res.count.toString();
						this.numarr = this.converStr(strNumber).split(",");
						this.config = {
							header: ["省份", "热风炉", "加热炉", "燃气炉", "CFB", "煤粉炉"],
							// data: res.province,
							data: this.prodd,
							// index: true,//序号
							// columnWidth: [50],
							align: ["center", "center", "center", "center", "center", "center"],
							rowNum: 12,
							oddRowBGC: "rgba(0,0,0,0)",
							evenRowBGC: "rgba(255,255,255,0)",
							columnWidth: [65],
						};
						if (res.province <= 11) {
							this.config.waitTime = 1000000000;
						}
						console.log(res.city,"城市")
						this.config1 = {
							data: this.city,
							colors: [
								"#0cffe980",
								"#0cffe980",
								"#0cffe980",
								"#0cffe980",
								"#0cffe980",
							],
							showValue: true,

						};

						function dealWithData() {
							var geoCoordMap = {
								海门: [121.15, 31.89],
								鄂尔多斯: [109.781327, 39.608266],
								招远: [120.38, 37.35],
								舟山: [122.207216, 29.985295],
								齐齐哈尔: [123.97, 47.33],
								盐城: [120.13, 33.38],
								赤峰: [118.87, 42.28],
								青岛: [120.33, 36.07],
								乳山: [121.52, 36.89],
								金昌: [102.188043, 38.520089],
								泉州: [118.58, 24.93],
								莱西: [120.53, 36.86],
								日照: [119.46, 35.42],
								胶南: [119.97, 35.88],
							};
							var data = [];
							for (var key in geoCoordMap) {
								data.push({
									name: key,
									value: geoCoordMap[key]
								});
							}
							return data;
						}

						let convertData = function() {
							let newSpread = [];
							for (let i in res.spread) {
								newSpread.push({
									name: res.spread[i].name,
									value: [
										res.spread[i].value[0],
										res.spread[i].value[1],
										res.spread[i].value[2],
										res.spread[i].value[3],
									],
								});
							}
							// console.log(newSpread, "")
							return newSpread;

						};
						var dataValue = dealWithData();
						// console.log(dataValue, "新地图")

						this.option = {
							title: {
								show: false,
							},
							tooltip: {
								trigger: "item",
								formatter: function(params) {
									// console.log(params)
									let result = '';
									const dotHtml =
										`<span style="color:#00f0ff;font-size:1.5vw;padding-bottom:2vh" class="el-icon-location">${params.name}</span>`;
									const dotHtml1 =
										'<span style="color:#87f7fe;padding-bottom:2vh">项目类型：</span>';
									const dotHtml2 = `<span style="color:#87f7fe">${params.value[3]}</span>`;
									const dotHtml3 =
										'<span style="color:#87f7fe;padding-bottom:2vh">项目名称：</span>';
									const dotHtml4 = `<span style="color:#87f7fe">${params.value[2]}</span>`;
									result += dotHtml + '</br>' + dotHtml1 + dotHtml2 + '</br>' + dotHtml3 +
										dotHtml4
									return result;
								},
								padding: 15,
								borderColor: '#6acafb',
								extraCssText: 'background:#00000040;color:#fff;background-size:100% 100%;'
							},
							color: ["#ddd"],
							geo: {
								map: "china",
								roam: false, //是否开启鼠标缩放和平移漫游
								label: {
									show: true, //是否显示对应地名
									color: "#00d5ff",
								},
								aspectScale: 0.8,
								zoom: 1.7,
								top: "30%", //组件距离容器的距离
								// 地图区域的多边形 图形样式
								itemStyle: {
									areaColor: {
										x: 0,
										y: 1,
										x2: 0,
										y2: 0,
										colorStops: [{
												offset: 0,
												color: "rgba(8,58,126,1)", // 0% 处的颜色
											},
											{
												offset: 1,
												color: "rgba(20,12,109,1)", // 100% 处的颜色
											},
										],
										global: false, // 缺省为 false
									}, // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
									shadowColor: "rgba(35,110,166,1)",
									shadowOffsetX: 0, //阴影水平方向上的偏移距离
									shadowOffsetY: 4, // 阴影垂直方向上的偏移距离
									borderWidth: 0, // 描边线宽 为 0 时无描边
								},
								// 高亮状态下的多边形和标签样式
								emphasis: {
									label: {
										show: true,
										color: "#fff",
									},
									itemStyle: {
										areaColor: {
											x: 0,
											y: 0,
											x2: 0,
											y2: 1,
											colorStops: [{
													offset: 0,
													color: "#551be7", // 0% 处的颜色
												},
												{
													offset: 0.5,
													color: "#2b5ceb", // 100% 处的颜色
												},
												{
													offset: 1,
													color: "#009dee", // 100% 处的颜色
												},
											],
											global: false, // 缺省为 false
										}, // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
									},
								},
							},
							series: [{
								name: "正常数据",
								type: "effectScatter",
								map: "china",
								roam: false, //是否开启鼠标缩放和平移漫游
								label: {
									show: false, //是否显示标签
									color: "#00d5ff",
								},
								aspectScale: 0.8,
								zoom: 1.7,
								top: "30%", //组件距离容器的距离
								itemStyle: {
									// color: "#ddb926",
									// color: "#fff",
									color: (e) => {
										// console.log(e, "color")
										return this.domColor(e.dataIndex, res.spread)
									},

									areaColor: {
										x: 1,
										y: 0,
										x2: 0,
										y2: 1,
										colorStops: [{
												offset: 0,
												color: "rgba(8,58,126,.2)", // 0% 处的颜色
											},
											{
												offset: 1,
												color: "rgba(20,12,109,.2)", // 100% 处的颜色
											},
										],
										global: false, // 缺省为 false
									}, // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
									borderWidth: 0.2, // 描边线宽 为 0 时无描边
									borderColor: "#3baddc", // 图形的描边颜色 支持的颜色格式同 color，不支持回调函数
									borderType: "solid", // 描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'
								},
								emphasis: {
									label: {
										formatter: "{b}",
										show: false,
										color: "#fff",
									},
									itemStyle: {
										areaColor: {
											x: 0,
											y: 0,
											x2: 0,
											y2: 1,
											colorStops: [{
													offset: 0,
													color: "#551be7", // 0% 处的颜色
												},
												{
													offset: 0.5,
													color: "#2b5ceb", // 100% 处的颜色
												},
												{
													offset: 1,
													color: "#009dee", // 100% 处的颜色
												},
											],
											global: false, // 缺省为 false
										}, // 地图区域的颜色 如果设置了visualMap，areaColor属性将不起作用
									},
								},
								coordinateSystem: "geo",
								data: convertData(),
								symbolSize: 8,
								showEffectOn: "render",
								rippleEffect: {
									brushType: "stroke",
								},
							}, ],
						};
					})
					.catch((err) => {
						this.loading = false;
						this.$message({
							message: "数据获取失败",
							type: "warning",
						});
					});
			},
			// 动图绘制
			drawLine() {
				this.$echarts.registerMap("china", china);
				let myChart = this.$echarts.init(document.getElementById("mapMain"));
				this.myChart = myChart;
				let that = this
				// 点击事件
				myChart.on('click', function(params) {
					if (params.value) {
						that.$router.push({
							path: '/cityMap',
							query: {
								cityName: params.name,
								cityData: JSON.stringify(params.value)
							}
						})
					} else {
						let mapArr = myChart.convertFromPixel('geo', [params.event.offsetX, params.event.offsetY])
						that.$router.push({
							path: '/cityMap',
							query: {
								cityName: params.name,
								cityData: JSON.stringify(mapArr)
							}
						})
					}
				});
				// 绘制图表
				myChart.setOption(this.option);
				window.addEventListener("resize", function() {
					myChart.resize();
				});
			},
			bgqh(row) {
				console.log(row);
			},
			// 打开更多各省市项目情况汇总
			toSummary() {
				this.$router.push({
					path: '/Solutionsum'
				})
			},
			// 打开通讯总览
			toOtherpage() {
				const data={
								user:this.UserInfo[1],
								event:"访问通讯总览"
							}
							log_add(data)
				this.$router.push({
					path: "/userotherPage"
				});
			},
			// 打开CEI页面
			toCeipage() {
				const data={
					user:this.UserInfo[1],
								event:"访问CEI统计页面"
							}
							log_add(data)
				this.$router.push({
					path: "/CeiPage"
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.perMap {
		width: 100vw;
		height: 100vh;
		background: url("~@/assets/images/map/map.jpg") no-repeat 0 0;
		background-size: cover;
		// overflow: hidden;
		.box{
padding-top: 4.5vh;
		}
		.mapContainer {
			width: 97vw;
			height: 91vh;
			margin: 0 auto;
			background: url("~@/assets/images/map/mapborder.png") no-repeat 0 0;
			background-size: 100% 100%;
			// margin-top: 4.5vh;
			position: relative;
			padding: 0.1px;

			//    padding-left: 2vw;
			//    padding-right: 2vw;
			.mapleft {
				width: 16vw;
				padding-left: 2vw;

				.mapleft-1 {
					width: 16vw;
					height: 16vh;
					padding-top: 4.5vh;

					.mapleft-1-data {
						padding-top: 2vh;
						width: 100%;
						color: #00eaff;
						justify-content: space-between;

						.name {
							color: #fff;
							font-size: 1vw;
						}

						.num {
							width: 9vw;
							height: 5vh;
							font-size: 2vw;
							background-color: #16d6ff10;
							text-align: center;
							line-height: 5vh;
						}
					}

					.mapleft-1-data1 {
						color: #ff7e00;

						.num {
							background-color: #ff7e0010;
						}
					}
				}

				.mapleft-2 {
					padding-top: 6vh;
					width: 16vw;
					height: 60vh;
					position: relative;
					left: auto;

					.mapleft-2-data {
						width: 7vw;
						height: 9vh;
						float: left;
						background: url("~@/assets/images/map/map2.png") no-repeat 0 0;
						background-size: 100% 100%;
						margin-top: 2vh;
						text-align: center;

						.name {
							font-size: 0.9vw;
							color: #5acede;
							line-height: 6vh;
							height: 2vh;
						}

						.num {
							font-size: 3vh;
							color: #fff;
							line-height: 2vh;
							height: 2vh;
						}
					}
				}
			}

			.mapcen {
				width: 56vw;
				padding-left: 2vw;

				.mapcen-tit {
					padding-top: 6vh;
					width: 19vw;
					height: 11vh;
					margin: 0 auto;
					text-align: center;

					//    padding-left: 7vw;
					.name {
						font-size: 1.2vw;
						font-weight: bold;
						color: #00f0ff;
						line-height: 3vh;
						font-family: "MF-ZheHei-Noncommercial-Regular";
					}

					.mapcen-data {
						justify-content: center;
						margin-top: 1vh;

						.num {
							width: 3vw;
							height: 8vh;
							line-height: 8vh;
							background: url("~@/assets/images/map/map3.png") no-repeat 0 0;
							background-size: 100% 100%;
							color: #fff;
							font-size: 5vh;
							margin-left: 1vw;
						}
					}
				}

				.mapchina {
					width: 56vw;
					height: 68vh;
				}
			}

			.mapright {
				width: 20vw;
				//    background: #fff;
				margin-right: 1vw;

				.mapright-1 {
					padding-top: 6vh;

					.words {
						margin-left: 8vw;
						margin-top: 1.5vh;
						font-size: 0.7vw;
						color: #6cf3ff;
					}
				}
			}

			.mapleft-1-tit {
				font-size: 1vw;
				color: #6cf3ff;
				padding-left: 1vw;
				height: 3vh;
				line-height: 3vh;
				background: url("~@/assets/images/map/map1.png") no-repeat 0 0;
				background-size: 100% 100%;
			}
		}

		.top-tit {
			width: 100%;
			font-size: 2vw;
			color: #ffffff;
			font-family: "MicrosoftYaHei";
			font-weight: normal;
			font-stretch: normal;
			text-align: center;
			position: absolute;
			// left: 50%;
			// margin-left: -9vw;
			top: 2vh;
		}

		.bot-tit {
			font-size: 1vw;
			color: #02eeff;
			position: absolute;
			bottom: 0;
			width: 100%;
			text-align: center;
			bottom: 4.5vh;
			cursor: pointer;
			z-index: 99;

			.bot-tit1 {
				margin-left: 42.5vw;
			}

			.bot-tit2 {
				margin-left: 8vw;
			}
		}
	}

	::v-deep {
		.dv-scroll-board {
			margin-top: 2vh;
			border: solid 1px #185378;

			.header {
				font-size: 0.7vw;
				background: none !important;
				border-bottom: solid 1px #185378;

				.header-item {
					text-overflow: unset;
					border-right: solid 1px #185378;
					padding: 0 !important;
				}

				.header-item:last-child {
					border-right: none;
				}
			}

			.rows {
				.row-item {
					height: 4vh !important;
					line-height: 4vh !important;
					color: #87f7fe;
					cursor: pointer;
				}

				.row-item:hover {
					background: #00f8ff10 !important;
				}
			}
		}

		.dv-capsule-chart .label-column {
			padding-right: 10px;
			display: block;

			div {
				height: 1vh;
				color: #d4fff9;
				line-height: 1.5vh;
				margin-bottom: 3vh;
			}
		}

		.capsule-container {
			display: block;
		}

		.unit-label {
			display: none;
		}

		.dv-capsule-chart .capsule-item {
			height: 1vh;
			border-radius: 1px;
			justify-content: unset;
			margin: 0;
			margin-bottom: 3vh;

			.capsule-item-column {
				height: 1vh;
				border-radius: 1px;

				.capsule-item-value {
					color: #0cffe9;
				}
			}
		}

		.dv-scroll-board .rows .ceil {
			text-align: center;
		}

		.el-dialog {
			background: rgba(0, 0, 0, 0.2) !important;
			border: rgb(8, 255, 243) 1px solid;
			backdrop-filter: blur(3px);
		}

		.el-dialog__title {
			color: rgb(255, 255, 255);
			font-weight: 1000;
		}

	}
</style>
